export default defineAppConfig({
  ui: {
    tooltip: {
      base: '[@media(pointer:coarse)]:hidden px-2 py-1 text-xs font-normal relative overflow-visible whitespace-normal break-words h-full',
    },
    icons: {
      dynamic: true,
      dark: 'i-ph-moon-duotone',
      light: 'i-ph-sun-duotone',
      search: 'i-ph-magnifying-glass-duotone',
      external: 'i-ph-arrow-up-right',
      chevron: 'i-ph-caret-down',
      hash: 'i-ph-hash-duotone'
    },
    notifications: {
      position: 'top-0 bottom-auto'
    },
    primary: 'sky',
    secondary: 'cool',
    gray: 'cool',
    button: {
      // rounded: 'rounded-full',
      default: {
        color: 'gray'
      }
    },
    input: {
      default: {
        size: 'md'
      }
    },
    card: {
      rounded: 'rounded-none'
    },
    dashboard: {
      panel: {
        content: {wrapper: 'flex-1 flex flex-col overflow-y-auto'},
      },
      section: {
        title: 'pl-4',
        description: 'pl-4',
      },
      modal: {
        width: 'sm:max-w-md'
      }
    },
    table: {
      wrapper: 'static overflow-x-auto overflow-visible',
      td: { padding: 'px-2 py-1' }
    },
    form: {
      container: 'm-4'
    },
    footer: {
      top: {
        wrapper: 'border-t border-gray-200 dark:border-gray-800',
        container: 'py-8 lg:py-16'
      },
      bottom: {
        wrapper: 'border-t border-gray-200 dark:border-gray-800'
      }
    },
    page: {
      hero: {
        wrapper: 'lg:py-24'
      }
    }
  }
})
